// Homepage slider
//// <<<MD
@include media-breakpoint-down(md) {
    div[class^='slider'] {

    }

    div[class^='slider'] .slide .slide-image {
        min-height: 40vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    div[class^='slider'] .slide .slide-image .slide-content {
        min-height: 40vh;
    }
}

//// MD>>>XL
@include media-breakpoint-between(md, xl) {
    div[class^='slider'] {
        max-height: 50vh;
    }

    div[class^='slider'] .slide .slide-image {
        min-height: 50vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    div[class^='slider'] .slide .slide-image .slide-content {
        min-height: 50vh;
    }
}

//// XL>>>
@include media-breakpoint-up(xl) {
    div[class^='slider'] {
        max-height: 30vh;
    }

    div[class^='slider'] .slide .slide-image {
        min-height: 30vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    div[class^='slider'] .slide .slide-image .slide-content {
        min-height: 30vh;
    }
}

div[class^='slider'] .slide .slide-image .slide-content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @extend .d-flex, .align-items-center, .py-lg-6, .py-md-3, .py-0, .slide-bg, .text-shadow !optional;
}

div[class^='slider'] .slider-list {
    @extend .w-100 !optional;
}

// Arrow Styling

.slider-prev,
.slider-next {
      bottom: calc(50% - 20px);
      height: 40px;
    width: 40px;
      z-index: 9;
    cursor: pointer;
    opacity: 0.8;
    @extend .box-shadow, .mx-1, .mx-md-5, .position-absolute !optional;
    i {
        color: $primary;
    }
}

.slider-prev:hover,
.slider-next:hover {
    opacity: 1;
}

.slider-next {
      right: 0;
}

.slick-initialized {
    @extend .relative !optional;
}

// Dots
.slick-dots {
    bottom: -8px !important;
    min-height: 35px;
    z-index: 999;
    @include media-breakpoint-up(md) {
        bottom: 0 !important;
    }

    li {
        margin-bottom: 5px;

        button:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro", serif !important;
            font-weight: 900 !important;
            font-size: 1rem !important;
            color: white;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
            opacity: 1;
            @extend .text-shadow !optional;
        }
    }

    li.slick-active button:before {
        color: $primary !important;
        font-weight: 900;
        content: "\f111";
        opacity: 1;
    }
}

.slick-dotted.slick-slider {
    @extend .mb-0 !optional;
}

.testimonial-slider {
    background-position:center center;
    background-size:cover;

    .slick-track {
        @extend .d-flex, .align-items-center !optional;

        .slick-slide {
            height: auto !important;

            .slider-content {
                @extend .col-10, .col-md-8, .col-lg-6, .mx-auto, .py-5, .text-white, .font-italic, .text-center !optional;

                .slider-title {
                    @extend .mt-2, .hero-text !optional;
                }
            }
        }
    }
}



