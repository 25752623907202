#contact {
    background-size: cover;
    background-position: center center;
    min-height: calc(100vh - 200px);
    @extend .py-5 !optional;

    .row {
        @extend .bg-white !optional;

    }
}
