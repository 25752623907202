// Tweaks op fonts
@include media-breakpoint-down(md) {
    h1, h2, h3, h4, h5, h6, {
        font-size: $font-size-sm * 1.5;
    }
    .hero-text {
        font-size: $font-size-base;
    }
}

@include media-breakpoint-up(md) {
    .hero-text {
        font-size: $font-size-base * 1.19;
    }
}

.font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button:focus {
    outline: 0 !important;
}

.box-shadow {
    -webkit-box-shadow: 0px 20px 60px -20px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 20px 60px -20px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 20px 60px -20px rgba(0, 0, 0, 0.5);
}

.text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

// VERANDERT CONTAINERFLUID NAAR CONTAINER OP LG+
@include media-breakpoint-up(xl) {
    .container-restraint {
        &.container-fluid {
            @include make-container();
            @include make-container-max-widths();
        }
    }
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

#rootsteps-vue {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #sticky-nav {
    position: fixed;
    top: 0;
    opacity: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: white;
    transform: translateY(-100%);
    transition: ease all 0.3s;

    &.active {
      opacity: 1;
      transition: ease all 0.3s;
      transform: translateY(0);
    }

  }

  .bg-dark-radial {
    background: radial-gradient(ellipse at center, darken(theme-color('primary'), 0%) 0%, darken(theme-color('secondary'), 10%) 100%);
  }

  /********************/
  /*  Pricing styling */
  /********************/
  section.pricing {
    background: theme-color('primary');
    background: -webkit-linear-gradient(to right, theme-color('primary'), lighten(theme-color('primary'), 3%), lighten(theme-color('primary'), 8%));
    background: linear-gradient(to right, theme-color('primary'), lighten(theme-color('primary'), 3%), lighten(theme-color('primary'), 8%));
  }

  .pricing .card {
    border: none;
    border-radius: 3px;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }

  .pricing hr {
    margin: 1.5rem 0;
  }

  .pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }

  .pricing .card-price {
    font-size: 3rem;
    margin: 0;
  }

  .pricing .card-price .period {
    font-size: 0.8rem;
  }

  .pricing ul li {
    margin-bottom: 1rem;
  }

  .pricing .text-muted {
    opacity: 0.7;
  }

  .pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
  }

  /* Hover Effects on Card */

  @media (min-width: 992px) {
    .pricing .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
      opacity: 1;
    }
  }

  /*********************************/
  /*    Form with image            */
  /*********************************/
  .login,
  .image {
    min-height: 50vh;
  }

  .bg-image {
    background-image: url('https://source.unsplash.com/Qu-dnz_Kqgw');
    background-size: cover;
    background-position: 15% center;
  }

  .login-heading {
    font-weight: 300;
  }

  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    @extend .rounded !optional;
  }

  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }

  .form-label-group > input,
  .form-label-group > text-area,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
    height: auto;
    @extend .rounded !optional;
  }

  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    cursor: text;
    /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }

  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }

  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-moz-placeholder {
    color: transparent;
  }

  .form-label-group input::placeholder {
    color: transparent;
  }

  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }

}