.video-name {
  @extend .my-2, .px-4, .py-3, .text-white, .text-center, .text-shadow, .d-flex, .align-items-center, .justify-content-between !optional;

  h3 {
    font-weight: 300;
    font-size: 1.4em;
    @extend .mb-0, .mx-auto !optional;
  }
}

.featured-videos {
  .row {
    .video-box {
      margin-top: 27px;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      &:first-of-type {
        @extend .w-100 !optional;

        .video-contain {
          height: auto;
          @extend .px-0 !optional;

          [id^=video--] {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            @include media-breakpoint-down(md) {
              height: 176px;
            }
            @include media-breakpoint-only(md) {
              height: 370px;
            }
            @include media-breakpoint-only(lg) {
              height: 505px;
            }
            @include media-breakpoint-up(xl) {
              height: 606px;
            }

            iframe {
              max-height: 100%;
              @include media-breakpoint-down(md) {
                height: 209px;
              }
              @include media-breakpoint-only(md) {
                height: 370px;
              }
              @include media-breakpoint-up(lg) {
                height: 624px;
              }
            }
          }
        }
      }

      .video-contain {
        @extend .px-3 !optional;
      }
    }
  }
}

#videos {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

  .videos {
    .halve {
      .video {
        @extend .mt-3 !optional;
      }
    }
  }
}

.video-contain {
  width: 100%;
  @include media-breakpoint-down(md) {
    height: 176px;
  }
  @include media-breakpoint-only(lg) {
    height: 234px;
  }
  @include media-breakpoint-up(xl) {
    height: 268px;
  }
  @extend .px-3 !optional;

  [id^=video--] {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    @extend .mx-auto !optional;

    iframe {
      width: 100%;
      height: 100%;
      background-color: $black !important;

      #player {
        @extend .h-100 !optional;
      }
    }
  }
}

.featured-videos {
  background-position: center center !important;
  background-size: cover !important;
  @extend .py-5, .position-relative !optional;

  .full, .halve {
    .video-padding {
      @extend .p-2 !optional;

      .video-description {
        @extend .mt-2, .mb-3, .px-4, .py-3, .text-white, .text-center, .text-shadow, .d-flex, .align-items-center, .justify-content-between !optional;

        .video-title {
          font-weight: 300 !important;
          font-size: 1.4em !important;
          @extend .mb-0, .mx-auto !optional;
        }
      }
    }
  }
}


