.custom-code {
  overflow: hidden;
  &.hidden-mobile {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
  &.hidden-desktop {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }
}
