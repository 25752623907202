.basic {
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &.hidden-mobile {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    &.hidden-desktop {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }
    @extend .d-flex, .align-items-center, .position-relative !optional;

    .basic-container {
        z-index: 5;

        .basic-row {

            .basic-col {
                overflow: hidden;
                &.offset.offset-mobile, &.offset.offset-desktop {
                    &.basic-col-1 {
                        left: 0;
                        &.padding-desktop {
                            margin-left: -1rem !important;
                        }
                    }
                    &.basic-col-2  {
                        right: 0;
                        &.padding-desktop {
                            margin-right: -1rem !important;
                        }
                    }
                    &.padding-mobile.left {
                        margin-left: -1rem !important;

                        .col-contain {
                            padding: 0 !important;
                        }
                    }
                    &.padding-mobile.right {

                        .col-contain {
                            padding: 0 0 0 1rem !important;
                        }
                    }
                    &.none-mobile, &.none-desktop, &.custom-mobile, &.custom-desktop {

                        .col-contain {
                            padding: 0 !important;
                        }
                    }
                    @extend .d-flex, .align-items-center !optional;
                }
                &.offset.offset-desktop {
                    @include media-breakpoint-up(lg) {
                        position: absolute !important;
                    }
                }
                &.no-offset {
                    &.basic-col-2  {
                        @extend .ml-auto !optional;
                    }
                }
                @extend .position-relative, .px-0 !optional;

                .background-image {
                    background-size: cover;
                    background-position: center;
                    @extend .absolute-0 !optional;
                }

                .col-contain {
                    &.smallest {
                        @include media-breakpoint-up(md) {
                            flex: 0 0 66.66666667%;
                            max-width: 66.66666667%;
                        }
                    }
                    &.medium {
                        @include media-breakpoint-up(md) {
                            flex: 0 0 83.33333333%;
                            max-width: 83.33333333%;
                        }
                    }
                    @extend .h-100, .d-flex, .flex-column !optional;

                    .title, .content, a, .image {
                        z-index: 1;
                        @extend .position-relative !optional;
                    }

                    .title {
                        @extend .mb-3 !optional;
                    }

                    .bg-image {
                        background-size: cover;
                        background-position: center;
                        &:not(.only) {
                            @extend .mb-3 !optional;
                        }
                        @include media-breakpoint-down(md) {
                            padding-top: 62.5%;
                        }
                        @extend .w-100, .h-100 !optional;
                    }

                    .image {
                        max-width: 100%;
                        &:not(.only) {
                            @extend .mb-3 !optional;
                        }
                        &.mobile {
                            @extend .d-md-none !optional;
                        }
                        &.desktop {
                            @include media-breakpoint-down(sm) {
                                display: none !important;
                            }
                        }
                    }

                    .video-box {
                        &:not(.only) {
                            @extend .mb-3 !optional;
                        }
                        &.mobile {
                            @extend .d-md-none !optional;
                        }
                        &.desktop {
                            @include media-breakpoint-down(sm) {
                                display: none !important;
                            }
                        }

                        .video {
                            max-width: 100%;
                            pointer-events: all;
                            top: initial;
                            left: initial;
                            transform: none;
                            min-width: initial;
                            position: relative !important;
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                            }
                        }
                    }
                }

                .col-contain > *:last-child {
                    @extend .mb-0 !optional;
                }

                a[class^='c-'], a[class*='c-'] {
                    width: fit-content;
                    &.large {
                        @extend .btn-lg !optional;
                    }
                    &.full-width {
                        @extend .w-100 !optional;
                    }
                    &.transparent {
                        box-shadow: none !important;
                    }
                    @extend .d-flex, .align-items-center, .mb-3 !optional;

                    .btn-icon {
                        height: 18px;
                        @extend .img-fluid !optional;
                    }
                }
            }
        }
    }

    video {
        pointer-events: none;
        min-width: 100%;
        left: 50%; /* % of surrounding element */
        top: 50%;
        transform: translate(-50%, -50%); /* % of current element */
        object-fit: cover;
        @extend .h-100, .position-absolute !optional;
    }
}
