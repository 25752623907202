// settings for this file
$logo-height: 52px;

main {
    padding-top: 72px;
    @include media-breakpoint-up(md) {
        padding-top: 68px;
    }
}

.nav-mobile {
    @extend .bg-white, .fixed-top, .d-block, .d-md-none, .box-shadow , .p-0!optional;

    .container {
        @extend .d-flex, .justify-content-between, .align-items-center, .p-2 !optional;

        .logo-box {
            @extend .d-flex, .d-md-none, .align-items-center !optional;

            .nav-logo {
                height: $logo-height;
                @extend .img-fluid !optional;
            }
        }

        .nav-button-box {
            @extend .d-flex, .justify-content-end, .align-items-center !optional;

            .nav-button {
                max-width: 32em;
                @extend .w-100 !optional;

                .nav-button-link {
                    height:40px;
                    width:40px;
                    border-width:thin!important;

                    &:focus {
                        box-shadow: none !important;
                    }
                    @extend .border, .border-white-10, .border-top-0, .border-left-0, .border-right-0, .rounded-0, .btn, .btn-primary, .d-flex, .align-items-center, .justify-content-center, .d-md-none !optional;

                    i {
                        text-shadow: 0 2px 4px rgba(0,0,0,0.25);
                        @extend .text-white !optional;
                    }
                }
            }
        }
    }

    .nav-collapse {
        @extend .bg-primary, .d-flex, .justify-content-center !optional;

        .nav-collapse-row {
            @extend .row, .w-100, .text-center !optional;

            #collapseMenu {
                @extend .d-md-none !optional;

                #structure-mobile {
                    @extend .py-3, .navbar-nav, .d-flex, .justify-content-between, .text-white, .list-unstyled !optional;

                    .nav-item {

                        .nav-link {
                            @extend .text-white !optional;
                        }
                    }
                }
            }
        }
    }
}

.nav-non-mobile {
    @extend .bg-white, .fixed-top, .box-shadow, .d-none, .d-md-block !optional;

    .container {
        @extend .d-flex, .justify-content-between, .align-items-center, .h-100 !optional;

        .logo-box {
            @extend .h-100, .d-none, .d-md-flex, .align-items-center !optional;

            .nav-logo {
                height: $logo-height;
                @extend .img-fluid !optional;
            }
        }

        .nav-contain {
            @extend .h-100, .d-flex, .justify-content-end, .align-items-center !optional;

            .navbar {
                @extend .p-0, .h-100, .w-100 !optional;

                .nav-box {
                    @extend .h-100, .d-flex, .align-items-center, .text-center !optional;

                    #structure-nonmobile {
                        @extend .h-100, .w-100, .text-center, .d-flex, .justify-content-between, .align-items-center !optional;

                        .nav-item {
                            @extend .px-3, .text-primary !optional;

                            &:active, &:focus {
                                @extend .bg-muted !optional;
                            }
                            &:hover {
                                @extend .font-weight-bold !optional;
                            }
                        }
                    }
                }
            }
        }
    }
}
