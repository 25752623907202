#contact, #contact-offerte-home {
    .contact-form {
        @extend .m-0, .w-100 !optional;

        .title {
            @extend .login-heading, .mb-2, .font-weight-bold !optional;
        }

        .content {
            @extend .mb-4 !optional;

            .content-link {
                @extend .font-weight-bold !optional;
            }
        }

        button {
            @extend .btn, .btn-lg, .btn-primary, .btn-block, .border-0, .box-shadow, .btn-login, .text-uppercase, .font-weight-bold, .mb-2 !optional;
        }

        .privacy-statement {
            @extend .text-center !optional;

            .privacy-statement-link {
                @extend .small !optional;
            }
        }
    }

    .alert {
        @extend .d-flex, .align-items-center, .justify-content-between, .p-2,
        .p-md-3, .mt-3, .border-0, .mb-2 !optional;

        .content {
            margin-bottom: 0 !important;
            @extend .mx-auto, .text-center, .hero-text !optional;
        }

        button {
            width: fit-content;
            margin-bottom: 0 !important;
        }
    }
}

#contact-offerte-home {
    background-size: cover;
    background-position: center center;

    a {
        @extend .text-white !optional;
    }
}

#contact {
    a {
        @extend .text-primary !optional;
    }
}