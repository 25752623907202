.pricing {
    background-position:center center !important;
    background-size:cover !important;
    @extend .py-5 !optional;

    .container {
        .pricing-row {
            @extend .py-5 !optional;

            .pricing-col {
                @extend .text-center, .w-100 !optional;

                h2 {
                    @extend .text-light, .text-uppercase, .mb-5 !optional;
                }
            }

            .pakket-1, .pakket-2 {
                .card {
                    @extend .mb-5, .mb-lg-0 !optional;
                }

                ul {
                    li {
                        &:last-of-type {
                            &:before {
                                content: '\2A09';
                                filter: none !important;
                            }
                            @extend .text-muted !optional;
                        }
                    }
                }
            }

            .pakket-3 {
                .card {
                    @extend .mb-0 !optional;
                }
            }

            .pakket-1, .pakket-2, .pakket-3 {
                .card-title {
                    font-size:1.6em !important;
                    @extend .text-center, .text-uppercase, .mt-2 !optional;
                }

                ul {
                    @extend .mb-3, .ml-3 !optional;

                    li {
                        &:before {
                            content: '\2713';
                            font-size: 24px;
                            filter: invert(62%) sepia(78%) saturate(199%) hue-rotate(86deg) brightness(94%) contrast(87%) !important;
                            @extend .mr-2 !optional;
                        }
                        @extend .d-flex, .align-items-center !optional;

                    }
                }

                .card-contact {
                    @extend .mt-4 !optional;

                    .card-price-position {
                        @extend .text-center, .mb-2 !optional;

                        .card-price {
                            font-size: 0.9em !important;
                            @extend .font-weight-bold !optional;
                        }
                    }

                    .card-button {
                        @extend .d-flex, .align-items-center, .justify-content-center !optional;

                        .card-button-link {
                            padding-top: 16px;
                            padding-bottom:16px;
                            @extend .btn, .px-4, .mt-2, .rounded, .btn-primary, .text-uppercase !optional;
                        }
                    }
                }
            }

        }
    }
}

#contact-offerte-home {
    @extend .bg-dark, .py-5 !optional;

    .container {
        @extend .px-0 !optional;

        .row {
            @extend .p-3 !optional;
        }
    }
}
