.hero {
    overflow: hidden;
    background-size: cover;
    @include media-breakpoint-down(sm) {
        background-position: top center;
    }
    @include media-breakpoint-up(md) {
        background-position: center;
    }
    &.hidden-mobile {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    &.hidden-desktop {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }
    &.small {

        .hero-slider-container {

            .slide {

                .gradient-contain {

                    .gradient-box {
                        @include media-breakpoint-up(md) {
                            top: -500px;
                        }
                    }
                }
            }
        }
    }
    &.medium {

        .hero-slider-container {

            .slide {

                .gradient-contain {

                    .gradient-box {
                        @include media-breakpoint-up(md) {
                            top: -400px;
                        }
                    }
                }
            }
        }
    }
    &.large {

        .hero-slider-container {

            .slide {

                .gradient-contain {

                    .gradient-box {
                        top: -200px;
                    }
                }
            }
        }
    }
    @extend .d-flex, .flex-column, .position-relative !optional;

    .hero-slider-container {
        padding: 0 !important;

        h1, h2, h3, h4 {
            font-weight: normal !important;
        }

        .slide {
            @extend .position-relative !optional;

            .slide-image-box {
                @extend .d-flex, .justify-content-end !optional;

                .slide-image {
                    background-size: cover;
                    background-position: center;
                    transform: rotate(45deg);
                    mix-blend-mode: multiply;
                    @include media-breakpoint-down(sm) {
                        height: 400px;
                        width: 380px;
                        left: -235px;
                    }
                    @include media-breakpoint-up(md) {
                        top: 80px;
                    }
                    @include media-breakpoint-between(md, lg) {
                        height: 606px;
                        width: 710px;
                        left: -326px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 700px;
                        width: 920px;
                        left: -365px;
                    }
                    @media screen and (min-width: 1800px) {
                        height: 750px;
                        left: -387px;
                        width: 1050px;
                    }
                }
            }

            .slide-video-box {

                .slide-video {

                    video {
                        height: 100% !important;
                    }
                }
            }

            .slide-video-box, .slide-image-box {
                border-radius: 50vw;
                @include media-breakpoint-down(sm) {
                    height: 400px;
                    width: 600px;
                }
                @include media-breakpoint-between(md, lg) {
                    height: 700px;
                    width: 1000px;
                }
                @include media-breakpoint-up(xl) {
                    height: 700px;
                    width: 1250px;
                }
                @media screen and (min-width: 1800px) {
                    height: 700px;
                    width: 1400px;
                }
            }

            .gradient-contain {
                z-index: 1;
                @extend .position-relative !optional;

                .gradient-box {
                    mix-blend-mode: multiply;
                    background-color: #df4147;
                    z-index: 1;
                    border-radius: 50vw;
                    transform: rotate(-45deg);
                    max-height: 700px;
                    overflow: hidden;
                    box-shadow: -40px 40px 120px #00000029;
                    @include media-breakpoint-down(sm) {
                        height: 400px;
                        width: 600px;
                        top: -100px;
                        right: -200px;
                    }
                    @include media-breakpoint-up(md) {
                        top: -200px;
                        right: -250px;
                    }
                    @include media-breakpoint-between(md, lg) {
                        height: 700px;
                        width: 1000px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 700px;
                        width: 1250px;
                    }
                    @media screen and (min-width: 1800px) {
                        height: 700px;
                        width: 1400px;
                    }
                    @extend .position-absolute !optional;
                }

                .gradient-text {
                    z-index: 999;
                    transform: rotate(45deg);
                    @include media-breakpoint-down(sm) {
                        width: 100vw;
                    }
                    @include media-breakpoint-up(md) {
                        width: calc(100vw - 15px);
                    }
                    @extend .d-flex, .justify-content-center, .position-absolute !optional;

                    .gradient-text-container  {
                        @extend .position-absolute !optional;

                        .gradient-text-row {

                            .gradient-text-content {

                                h1, h2, h3, p {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .slide-container, .gradient-text  {

            .slide-row, .gradient-text-row {
                @extend .justify-content-center, .align-items-center, .position-relative !optional;

                .slide-content, .gradient-text-content {
                    &.col-md-10 {
                        @include media-breakpoint-between(md, lg) {
                            padding-left: 10%;
                        }
                        &:not(.col-xl-8) {
                            @include media-breakpoint-up(xl) {
                                padding-left: 10%;
                            }
                        }

                    }
                    &.col-xl-8 {
                        @include media-breakpoint-up(xl) {
                            padding-right: 16.66666667%;
                        }
                    }

                    h2 {
                        display: inline-block;
                    }

                    a {
                        border-radius: 5px;
                        box-shadow: 20px 20px 60px #00000034;
                        @extend .p-3, .d-inline-flex, .justify-content-between, .align-items-center !optional;

                        img {
                            height: 25px;
                            width: auto;
                        }
                    }
                }
            }
        }

        .dots-container {
            &.medium, &.small {

                .slick-dots-row {

                    .slick-dots-col {
                        @extend .col-md-2 !optional;
                    }

                    .slick-dots {
                        @extend .col-12, .col-md-8 !optional;
                    }
                }
            }
            @extend .position-relative, .d-flex, .flex-column-reverse !optional;

            .slick-dots {
                width: initial;
                position: relative;
                @include media-breakpoint-down(sm) {
                    min-height: 150px;
                }
                @include media-breakpoint-up(md) {
                    min-height: 50px;
                    padding-left: 8px;
                }
                @extend .d-flex, .col-xl-10 !optional;

                li {
                    width: -webkit-fill-available;
                    width: -moz-available;
                    height: 40px;
                    padding-top: 19px;
                    background: linear-gradient(rgba(255, 255, 255, 0) 18px, #00000033 20px, rgba(255, 255, 255, 0) 22px) !important;
                    &.slick-active {

                        button {
                            width: 100% !important;
                        }
                    }
                    @extend .position-relative !optional;


                    button {
                        padding: 0;
                        width: 0 !important;
                        height: 2px !important;
                        background-image: none !important;
                        background-size: initial;
                        background-position: initial;
                        background-color: var(--darkColor);
                        filter: none;
                        &::before {
                            display: none;
                        }
                        @extend .box-shadow !optional;
                    }

                    .dots-content {
                        margin-top: 1rem !important;
                        margin-right: 5px;
                        margin-left: 5px;
                        @extend .px-0, .text-left, .d-none, .d-md-block !optional;

                        h2 {
                            font-size: 18px !important;
                            line-height: 21px;
                            color: var(--darkColor);
                        }
                        h3 {
                            font-size: 12px !important;
                            line-height: 14px;
                            color: var(--primaryColor);
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .hero-container {
        z-index: 5;
        flex-grow: 1;
        @extend .d-flex !optional;

        .hero-title-col {
            @extend .mx-auto !optional;
        }

        .summary-row {
            font-size: 1.33em;
            @extend .row, .flex-column, .mx-auto !optional;

            .summary-col {

                .summary-append, .summary-prepend {
                    @extend .d-inline-flex, .flex-column !optional;


                    .summary-append-btn {
                        &.icon-before {
                            @extend .flex-row-reverse !optional;
                            .btn-icon {
                                @extend .mr-2, .ml-0 !optional;
                            }
                        }
                        @extend .d-inline-flex, .align-items-center !optional;

                        .btn-icon {
                            width: 18px;
                            height: 18px;
                            @extend .img-fluid, .ml-2, .mr-0 !optional;
                        }
                    }
                }

                .content {

                    .reverse {
                        @extend .d-flex, .flex-column-reverse !optional;
                    }
                }
            }
        }

        // explicitly calling these to prevent scss from stacking, we may want to move these to general and call these everywhere where we are using buttons. Buttons are a generic element after all...
        .buttons {
            @extend .d-inline-flex, .align-items-md-center !optional;
        }
    }

    aside {
        &.highlights {
            z-index: 5;
            @extend .mt-auto !optional;

            .aside-container {

                .aside-main-row {

                    .aside-main-col {
                        @extend .px-0 !optional;

                        .aside-carrier {
                            @extend .d-flex, .w-100, .m-0 !optional;

                            .aside-highlight {
                                @extend .px-3, .py-4, .p-md-4, .p-lg-5 !optional;
                            }
                        }
                    }
                }
            }
        }
    }

    video {
        pointer-events: none;
        min-width: 100%;
        left: 50%; /* % of surrounding element */
        top: 50%;
        transform: translate(-50%, -50%); /* % of current element */
        object-fit: cover;
        &.desktop-video {
            width: 177.77777778vh; /* 100 * 16 / 9 */
            min-height: 56.25vw; /* 100 * 9 / 16 */
            @extend .d-none, .d-md-flex !optional;
        }
        &.mobile-video {
            @extend .d-md-none !optional;
        }
        @extend .h-100, .position-absolute !optional;
    }

    .icon {
        background-position: center left;
        background-size: contain;
        background-repeat: no-repeat;
        &.custom {
            @include media-breakpoint-down(lg){
                transform:scale(0.9);
            }
        }
        &.small {
            height: 32px;
            width: 32px;
            @include media-breakpoint-up(lg) {
                height: 38px;
                width: 38px;
            }
        }
        &.medium {
            height: 48px;
            width: 48px;
            @include media-breakpoint-up(lg) {
                height: 54px;
                width: 54px;
            }
        }
        &.large {
            height: 64px;
            width: 64px;
            @include media-breakpoint-up(lg) {
                height: 72px;
                width: 72px;
            }
        }
        @extend .mb-3, .d-inline-flex, .img-fluid !optional;
    }
}
