.advanced {
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &.hidden-mobile {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    &.hidden-desktop {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }
    @extend .d-flex, .align-items-center, .position-relative !optional;

    .advanced-container {
        z-index: 5;
        .advanced-title-col {
            @extend .mx-auto !optional;
        }

        .summary-row {
            @extend .row, .flex-column, .mx-auto, .no-gutters !optional;
            .summary-col {

                .summary-contain {

                    .summary {

                        .content {

                            p:last-of-type {
                                margin-bottom:0;
                            }
                        }
                    }
                }

                .summary-append {
                    .btn {
                        @extend .d-flex, .align-items-center !optional;
                        &.icon-before {
                            @extend .flex-row-reverse !optional;
                            .btn-icon {
                                @extend .mr-2, .ml-0 !optional;
                            }
                        }
                        .btn-icon {
                            width: 18px;
                            height: 18px;
                            @extend .img-fluid, .ml-2, .mr-0 !optional;
                        }
                    }
                }
            }
        }

        .columns {
            .advanced-column-col {
                @extend .p-3 !optional;

                .col-contain {
                    overflow: hidden;

                    &.boxed {
                        @extend .p-3, .box-shadow !optional;
                    }
                    &.rounded {
                        @extend .rounded !optional;
                    }
                    &.extra-spacing {
                        @extend .p-4, .p-xl-5 !optional;
                    }

                    .background-image {
                        background-size: cover;
                        background-position: center;
                        @extend .absolute-0 !optional;
                    }

                    .content {
                        width: fit-content;
                        @extend .w-100 !optional;

                        p:last-of-type {
                            margin-bottom:0;
                        }
                    }

                    .column-link {
                        top:0;
                        right:0;
                        bottom:0;
                        left:0;
                        z-index: 3;
                        @extend .position-absolute !optional;
                    }

                    .btn-contain {
                        @extend .w-100 !optional;
                    }

                    .image, .content, .btn-contain {
                        z-index: 2;
                        @extend .position-relative !optional; // to prevent backdrop overlap
                    }

                    .background {
                        background-position: center;
                        background-repeat: no-repeat;
                        @extend .w-100 !optional;
                    }
                    @extend .position-relative, .w-100, .h-100, .p-0 !optional;
                }
            }
        }

        // explicitly calling these to prevent scss from stacking, we may want to move these to general and call these everywhere where we are using buttons. Buttons are a generic element after all...
        .buttons {
            @extend .d-inline-flex, .align-items-md-center !optional;
        }
    }

    video {
        pointer-events: none;
        min-width: 100%;
        left: 50%; /* % of surrounding element */
        top: 50%;
        transform: translate(-50%, -50%); /* % of current element */
        object-fit: cover;
        @extend .h-100, .position-absolute !optional;
    }
}
