.featured-image {
    &.hidden-mobile {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    &.hidden-desktop {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }
    @extend .d-flex, .position-relative !optional;

    .image-carrier {
        background-position: center center;
        background-size: cover;
        top:0;
        left:0;
        right:0;
        bottom:0;
        &.parallax {
            @include media-breakpoint-down(sm) {
                background-attachment: scroll !important;
            }
            background-attachment: fixed;
            background-size: cover;
            position: relative;
        }
        @extend .w-100, .h-100, .position-absolute !optional;
    }

    .image-container {
        z-index: 10;
        @extend .d-flex !optional;

        .image-carrier-row {
            @extend .w-100 !optional;

            .content {
                @extend .d-flex, .flex-column !optional;

                // explicitly calling these to prevent scss from stacking, we may want to move these to general and call these everywhere where we are using buttons. Buttons are a generic element after all...
                .buttons {
                    @extend .d-inline-flex, .flex-column, .flex-md-row, .align-items-md-center !optional;

                    .summary-append-btn {
                        width: fit-content;
                        &.icon-before {
                            @extend .flex-row-reverse !optional;

                            .btn-icon {
                                @extend .mr-2, .ml-0 !optional;
                            }
                        }
                        &:first-of-type {
                            margin-left: 0 !important;
                        }
                        @extend .d-flex, .align-items-center, .box-shadow, .mx-1, .mb-2, .mb-md-0 !optional;

                        .btn-icon {
                            width: 18px;
                            height: 18px;
                            @extend .img-fluid, .ml-2, .mr-0 !optional;
                        }
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
